import { Temporal } from '@js-temporal/polyfill';
import {
  TZ_EUROPE_LONDON,
  formatRelativeInstant,
  now,
  parseInstant,
  todayLocal,
} from '@packfleet/datetime';
import { IconButton } from '@packfleet/ui';
import classNames from 'classnames';
import {
  CallDetailsFragment,
  useIncomingCallSubscription,
} from 'generated/graphql';
import { useCurrentUser } from 'hooks/useCurrentUser';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Routes, linkTo } from 'utilities/routes';
import { hasOfficeRole } from '../users/utils';

type CallDetails = Omit<CallDetailsFragment, 'calledAt'> & {
  calledAt: Temporal.Instant;
};

const EXPIRY_MINUTES = 10;

const IncomingCallsList = () => {
  const user = useCurrentUser();
  const [calls, setCalls] = useState<Record<string, CallDetails>>({});
  const [recompute, setRecompute] = useState(0);
  const triggerRecompute = useCallback(() => setRecompute((r) => r + 1), []);

  useIncomingCallSubscription({
    skip: !user?.staffMember || !hasOfficeRole(user.staffMember.role),
    shouldResubscribe: true,
    onSubscriptionData: (options) => {
      const callDetails = options.subscriptionData.data?.incomingCall?.call;
      if (callDetails) {
        setCalls((calls) => ({
          ...calls,
          [callDetails.id]: {
            ...callDetails,
            calledAt: parseInstant(callDetails.calledAt),
          },
        }));
      }
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: recompute
  const sortedCalls = useMemo(
    () =>
      Object.values(calls)
        .filter(
          (c) => c.calledAt.until(now()).total('minutes') < EXPIRY_MINUTES,
        )
        .sort((a, b) => Temporal.Instant.compare(b.calledAt, a.calledAt)),
    [calls, recompute],
  );

  // Ensure that we re-compute the list of calls
  // when one reaches the expiry
  const oldestCall = sortedCalls.at(-1);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: triggerRecompute
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: recompute
  useEffect(() => {
    if (oldestCall) {
      const msUntilExpired = oldestCall.calledAt
        .add({ minutes: EXPIRY_MINUTES })
        .since(now())
        .total('milliseconds');

      const timer = setTimeout(
        triggerRecompute,
        Math.min(msUntilExpired, 60 * 1000), // At least once per minute, so the times update
      );
      return () => clearTimeout(timer);
    }
  }, [oldestCall, recompute]);

  if (!sortedCalls.length) {
    return null;
  }

  const onClose = (call: CallDetails) => {
    setCalls((calls) => {
      const { ...rest } = calls;
      delete rest[call.id];
      return rest;
    });
  };

  return (
    <div className="fixed right-4 top-1 z-50 group">
      <div className="overflow-hidden opacity-0 group-hover:opacity-100 transition-opacity flex justify-end">
        <button
          className="flex items-center gap-2 hover:bg-faded/50 px-2 py-1 my-1 rounded"
          onClick={() => setCalls({})}
        >
          <span className="text-xs text-secondary">Close All</span>
          <IoClose className="text-secondary text-xs" />
        </button>
      </div>
      <div className="flex flex-col gap-2">
        {sortedCalls.map((c, index) => {
          const href = c.currentRouteActivity
            ? linkTo(Routes.routeActiveDriverActivity, {
                date: c.currentRouteActivity.date,
                vehicleId: c.currentRouteActivity.vehicleId.toString(),
                activityId: c.currentRouteActivity.routeActivityId.toString(),
              })
            : linkTo(Routes.routeActive, {
                date: todayLocal(TZ_EUROPE_LONDON).toString(),
              });

          return (
            <div
              key={c.id}
              className={classNames(
                'bg-primary rounded hover:bg-primary flex justify-between shadow-lg animate-swingIn swingIn-top transition-[margin-top]',
                { '-mt-16 group-hover:mt-0': index !== 0 },
              )}
              style={{ zIndex: 10 + sortedCalls.length - index }}
            >
              <Link href={href} className="block m-3">
                <div className="flex justify-between">
                  <p className="text-lg">
                    Incoming call from {c.fromUser?.name || 'Unknown'}
                  </p>
                </div>

                <p className="text-sm text-secondary mr-3">
                  {formatRelativeInstant(c.calledAt)}
                </p>
              </Link>
              <IconButton
                icon={IoClose}
                onClick={() => onClose(c)}
                aria-label="close"
                className="float-right"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const IncomingCall = () => {
  const user = useCurrentUser();

  if (!user) {
    return null;
  }

  return <IncomingCallsList />;
};
