import {
  EmployerFragment,
  EmploymentType,
  StaffMemberFragment,
  StaffRole,
} from '@/generated/graphql';

export function hasOfficeRole(role: StaffRole): boolean {
  return [StaffRole.Admin, StaffRole.Engineer, StaffRole.Office].includes(role);
}

export function getRoleFriendlyName(role: StaffRole): string {
  switch (role) {
    case StaffRole.Admin:
      return 'Admin';
    case StaffRole.Driver:
      return 'Driver';
    case StaffRole.Engineer:
      return 'Engineer';
    case StaffRole.Office:
      return 'Office';
    case StaffRole.Warehouse:
      return 'Warehouse';
    case StaffRole.WarehouseLead:
      return 'Warehouse Lead';
  }
}

export function getEmployerName(
  staffMember:
    | (Pick<StaffMemberFragment, 'employmentType'> & {
        employer?: Pick<EmployerFragment, 'name'> | null;
      })
    | null
    | undefined,
) {
  const employer = staffMember?.employer;
  const isWorker = staffMember?.employmentType === EmploymentType.Worker;

  return employer ? `${employer.name}${isWorker ? ' - Worker' : ''}` : null;
}
