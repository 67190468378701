import { TZ } from '@/utilities/timezone';
import { Temporal } from '@js-temporal/polyfill';
import {
  formatFriendlyPlainDateShort,
  formatPlainDate,
  todayLocal,
} from '@packfleet/datetime';
import { usePollWhenFocused } from '@packfleet/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { truthy } from 'utilities/truthy';
import { useListRoutingStopsForDateRequiringActionQuery } from '../../generated/graphql';
import { API_BASE_URL } from '../../graphql/client';
import { axiosInstance } from '../../utilities/axios';
import { downloadFile } from '../../utilities/file';
import { Routes, linkTo } from '../../utilities/routes';
import { pluralize } from '../../utilities/string';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import FormLabel from '../form/FormLabel';
import Loading from '../loading/Loading';
import { ToggleButtons } from '../toggle-button/ToggleButtons';

export type Props = {
  date: Temporal.PlainDate;
};

export const PrintShipmentLabelsForAddressChanges = ({ date }: Props) => {
  const [sortOrder, setSortOrder] = useState<'shelf-order' | 'tracking-number'>(
    'shelf-order',
  );
  const [downloadingLabels, setDownloadingLabels] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { data: stopsData, refetch } =
    useListRoutingStopsForDateRequiringActionQuery({
      variables: {
        input: {
          date: formatPlainDate(date),
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  usePollWhenFocused({ refetch, interval: 60 * 1000 });

  const shipments = useMemo(
    () =>
      stopsData?.listRoutingStopsForDateRequiringAction?.stopsWithChangedLocations
        ?.map((s) => s.shipment)
        .filter(truthy) || [],
    [stopsData],
  );

  const labelShipmentIds = useMemo(() => {
    return shipments
      ?.sort((a, b) => a.trackingNumber.localeCompare(b.trackingNumber))
      .map((s) => s.id)
      .filter(truthy);
  }, [shipments]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: refetch, sortOrder
  const print = useCallback(async () => {
    setDownloadingLabels(true);
    try {
      const result = await axiosInstance.post(
        API_BASE_URL + linkTo(Routes.apiMerchantGenerateLabels),
        {
          shipmentIds: labelShipmentIds,
          size: 'rect',
          orderBy: sortOrder,
        },
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      downloadFile(
        result.data,
        `pathfinder-labels-${formatFriendlyPlainDateShort(todayLocal(TZ))}.pdf`,
        'application/pdf',
      );
      setDownloadingLabels(false);
      await refetch();
    } catch (_e) {
      setError('Something went wrong. Please try again in a few seconds.');
      setDownloadingLabels(false);
    }
  }, [labelShipmentIds]);

  const unprintedLabels = useMemo(
    () =>
      shipments?.filter(
        (s) =>
          !s.generatedLabels?.find(
            (l) =>
              l.deliveryLocationId === s.delivery.location.id &&
              // If the label has a file path present then this record was created automatically
              // and may not yet have been printed so we should also check the downloadedAt flag.
              // This is a new field so will not exist on old shipments. For older shipments where
              // the file path is not present we can assume these have already been downloaded.
              (l.filePath == null || l.downloadedAt != null),
          ),
      ),
    [shipments],
  );
  const hasUnprintedLabels = unprintedLabels.length > 0;
  const labelsToShow = hasUnprintedLabels ? unprintedLabels : shipments;

  if (!labelsToShow.length) {
    return null;
  }

  return (
    <div>
      {error ? <p className="text-warning">{error}</p> : null}
      <Dialog
        title="Print Labels"
        onConfirm={print}
        trigger={
          <Button
            color={hasUnprintedLabels ? 'warning' : 'neutral'}
            mode={hasUnprintedLabels ? 'primary' : 'outline'}
            className="w-full"
            disabled={downloadingLabels}
          >
            <Loading when={downloadingLabels} className="mr-2" /> 🏷{' '}
            {hasUnprintedLabels
              ? `${unprintedLabels.length} ${pluralize(
                  unprintedLabels.length,
                  'shipment needs',
                  'shipments need',
                )} a new label`
              : `Download ${shipments.length} changed ${pluralize(
                  shipments.length,
                  'label',
                  'labels',
                )}`}
          </Button>
        }
      >
        <FormLabel>
          Sort labels by:
          <ToggleButtons
            className="bg-secondary mt-2"
            buttons={[
              { label: 'Shelf location', value: 'shelf-order' },
              { label: 'Alphabetically', value: 'tracking-number' },
            ]}
            value={sortOrder}
            onClick={setSortOrder}
          />
        </FormLabel>
      </Dialog>
    </div>
  );
};
