const DEFAULT_PACK_SIZE_CC = 30 * 30 * 30;

// These values must match the values in services/routing/src/constants.ts
const SMALL_CAPACITY_PACKS = 80;
const MEDIUM_CAPACITY_PACKS = 100;
const LARGE_CAPACITY_PACKS = 200;
const LUTON_CAPACITY_PACKS = 500;
const CAR_CAPACITY_PACKS = 25;

export type CapacitySize = 'car' | 'small' | 'medium' | 'large' | 'luton';

export function getCapacityCc(capacity: CapacitySize) {
  return (
    (capacity === 'luton'
      ? LUTON_CAPACITY_PACKS
      : capacity === 'large'
        ? LARGE_CAPACITY_PACKS
        : capacity === 'medium'
          ? MEDIUM_CAPACITY_PACKS
          : capacity === 'small'
            ? SMALL_CAPACITY_PACKS
            : CAR_CAPACITY_PACKS) * DEFAULT_PACK_SIZE_CC
  );
}

const LUTON_CAPACITY_KG = 1400;
const LARGE_CAPACITY_KG = 1000;
const MEDIUM_CAPACITY_KG = 910;
const SMALL_CAPACITY_KG = 900;
const CAR_CAPACITY_KG = 500;

export function getCapacityKg(capacity: CapacitySize) {
  return capacity === 'luton'
    ? LUTON_CAPACITY_KG
    : capacity === 'large'
      ? LARGE_CAPACITY_KG
      : capacity === 'medium'
        ? MEDIUM_CAPACITY_KG
        : capacity === 'small'
          ? SMALL_CAPACITY_KG
          : CAR_CAPACITY_KG;
}

export function getVehicleType(capacityKg: number) {
  return capacityKg === LUTON_CAPACITY_KG
    ? 'luton'
    : capacityKg === LARGE_CAPACITY_KG
      ? 'large'
      : capacityKg === MEDIUM_CAPACITY_KG
        ? 'medium'
        : capacityKg === SMALL_CAPACITY_KG
          ? 'small'
          : capacityKg === CAR_CAPACITY_KG
            ? 'car'
            : undefined;
}

export const VAN_SIZES = [
  { label: "Doesn't have their own van", value: 'none' },
  { label: 'Car', value: 'car' },
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'Luton', value: 'luton' },
] as const;

export function getVehicleDimensionsForType(capacity: CapacitySize) {
  switch (capacity) {
    case 'car':
      return {
        widthCm: 87,
        heightCm: 87,
        lengthCm: 87,
      };
    case 'small':
      return {
        widthCm: 125,
        heightCm: 125,
        lengthCm: 138,
      };

    case 'medium':
      return {
        widthCm: 138,
        heightCm: 130,
        lengthCm: 155,
      };

    case 'large':
      return {
        widthCm: 160,
        heightCm: 140,
        lengthCm: 245,
      };

    case 'luton':
      return {
        widthCm: 170,
        heightCm: 180,
        lengthCm: 445,
      };
  }
}

export function getVehicleTypeFromCapacityCc(capacityCc: number) {
  const packs = Math.floor(capacityCc / DEFAULT_PACK_SIZE_CC);

  // Midpoints between vehicle sizes for range calculations
  const carSmallMidpoint = Math.floor(
    (CAR_CAPACITY_PACKS + SMALL_CAPACITY_PACKS) / 2,
  );
  const smallMediumMidpoint = Math.floor(
    (SMALL_CAPACITY_PACKS + MEDIUM_CAPACITY_PACKS) / 2,
  );
  const mediumLargeMidpoint = Math.floor(
    (MEDIUM_CAPACITY_PACKS + LARGE_CAPACITY_PACKS) / 2,
  );
  const largeLutonMidpoint = Math.floor(
    (LARGE_CAPACITY_PACKS + LUTON_CAPACITY_PACKS) / 2,
  );

  if (packs >= largeLutonMidpoint) return 'luton';
  if (packs >= mediumLargeMidpoint) return 'large';
  if (packs >= smallMediumMidpoint) return 'medium';
  if (packs >= carSmallMidpoint) return 'small';
  return 'car';
}

export function getVehicleSize(capacityKg: number) {
  const size = getVehicleType(capacityKg);
  return size
    ? size.charAt(0).toUpperCase() + size.slice(1)
    : `${capacityKg}kg`;
}

export function getVehicleSizeAbbreviated(capacityKg: number) {
  const size = getVehicleType(capacityKg);
  return getAbbreviatedSize(size);
}

export function getAbbreviatedSize(size: string | undefined) {
  return size === 'luton'
    ? ('Lu' as const)
    : size === 'large'
      ? ('L' as const)
      : size === 'medium'
        ? ('M' as const)
        : size === 'small'
          ? ('S' as const)
          : size === 'car'
            ? ('C' as const)
            : ('U' as const);
}
